@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.header {
    background-color: white;
    height: rem(95);
    display: flex;
    align-items: center;
    padding-top: rem(20);
    padding-bottom: rem(20);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;

    @include min-media('t') {
        height: rem(125);
    }

    @include min-media('xl') {
        height: rem(174);
    }

    &__logo {
        width: rem(70);
        height: rem(35);

        @include min-media('xl') {
            width: rem(88);
            height: rem(44);
        }
    }

    &__logo-img {
        display: block;
        width: 100%;
        height: 100%;
    }

    &__button {
        margin-left: auto;
    }
}
