@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.card-service {
    padding: rem(74) rem(31);
    border: 1px solid $color-3;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @include max-media('sMax') {
        padding: rem(74) rem(20);
    }

    @include max-media('mMax') {
        min-height: rem(538);
    }

    &__title {
        margin-bottom: rem(15);
        display: inline-block;
        color: $text;
        transition: color 0.3s ease;

        &[href]:hover {
            color: $primary;
        }

        @include min-media('t') {
            margin-bottom: rem(20);
        }
    }

    &__text {
        margin-bottom: rem(30);
        display: block;

        @include min-media('t') {
            max-width: rem(441);
            margin-bottom: rem(40);
        }

        @include min-media('xl') {
            max-width: 100%;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: rem(-7);

        @include min-media('t') {
            margin: auto rem(-7) rem(-7);
        }
    }

    &__item {
        margin: rem(7);
    }
}
