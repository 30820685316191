@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.list-technology {
    position: relative;

    &__title {
        margin-bottom: rem(40);
        display: inline-block;
        color: $text;

        @include min-media('t') {
            margin-bottom: rem(59);
        }
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        margin: rem(-17.5) rem(-30);

        @include max-media('sMax') {
            margin: rem(-17.5) rem(-20);
        }

        @include min-media('t') {
            margin: rem(-16);
        }

        @include min-media('xl') {
            margin: rem(-40);
        }

        & > * {
            margin: rem(17.5) rem(30);

            @include max-media('sMax') {
                margin: rem(17.5) rem(20);
            }

            @include min-media('t') {
                margin: rem(15);
            }

            @include min-media('xl') {
                margin: rem(40);
            }
        }
    }
}
