// Breakpoints
$min-media-sizes: (
	"m": 375px,
	"t": 768px,
	'md': 1024px,
	"d": 1280px,
	"xl": 1440px,
	"xxl": 1600px,
	"xxxl": 1920px
);

$max-media-sizes: (
	"sMax": 374px,
	"mMax": 767px,
	"tMax": 1023px,
	"dMax": 1279px,
	"xlMax": 1439px
);

// Colors
$primary: #E50058;
$text: #070707;
$color-1: #6A6A6A;
$color-2: #F4F4F9;
$color-3: #E0E0E0;
$color-4: #fff;
$color-5: #9D9D9D;
$color-6: #BDBDBD;
$color-7: #000;
