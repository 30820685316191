@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.tag {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $text;
    height: rem(40);
    padding: rem(8) rem(19);
    background-color: $color-2;
    border-radius: rem(22);
    transition: background-color 0.3s ease, color 0.3s ease;

    @include max-media('sMax') {
        padding: rem(8) rem(15);
        font-size: rem(14);
    }

    @include min-media('t') {
        padding: rem(6) rem(19) rem(10);
    }

    & > * {
        position: relative;
        pointer-events: none;
    }

    &:hover {
        background-color: $primary;
        color: $color-4;
    }
}
