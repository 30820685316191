@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.form {
    &__footer {
        display: flex;
        align-items: center;
        margin-top: rem(40);

        @include max-media('mMax') {
            flex-direction: column;
        }
    }

    &__button {
        margin: auto;
    }

    &__agreement {
        width: 100%;
        display: block;
        color: $color-6;
        font: 600 rem(12)/rem(14) 'Gilroy', sans-serif;
        margin-bottom: rem(30);

        @include min-media('t') {
            width: rem(301);
            margin-bottom: 0;
        }

        @include min-media('md') {
            width: rem(194);
        }

        & + .form__button {
            margin-left: auto;
            margin-right: 0;

            @include max-media('mMax') {
                width: 100%;
            }
        }
    }

    &__field {
        & + & {
            margin-top: rem(20);
        }

        &--radio {
            margin-top: rem(50);
        }
    }

    .grecaptcha-badge {
        visibility: visible !important;
        width: 256px;
        height: 60px;
        display: block;
        transition: right 0.3s ease 0s;
        position: fixed;
        bottom: 14px;
        right: -186px;
        box-shadow: grey 0px 0px 5px;
        border-radius: 2px;
        overflow: hidden;
        z-index: 99 !important;
    }
}
