@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.introduction {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    min-height: 100vh;

    &__title {
        margin-bottom: rem(20);
        width: 100%;
        display: block;

        @include min-media('t') {
            max-width: rem(519);
            margin-bottom: rem(40);
            margin-top: rem(25);
        }

        @include min-media('xl') {
            max-width: rem(576);
            margin-top: 0;
        }
    }

    &__subtitle {
        width: 100%;
        display: block;

        @include min-media('t') {
            max-width: rem(500);
        }

        @include min-media('xl') {
            max-width: rem(541);
        }
    }
}
