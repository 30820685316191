html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Gilroy', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

button,
input,
textarea,
select {
    background: none;
    font-family: 'Gilroy', sans-serif;
    border: 0;
    border-radius: 0;
    outline: 0;
    transition: border 0.3s ease,color 0.3s ease;
    cursor: pointer;
    padding: 0;
}

h1, h2, h3, p {
  margin: 0;
}

* {
  box-sizing: border-box;
}
