@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.list-radio {
    position: relative;

    &__title {
        margin-bottom: rem(20);
        display: inline-block;
        color: $text;
    }

    &__items {
        display: flex;
        flex-wrap: wrap;

        & > * {
            width: 100%;
            white-space: nowrap;

            @include min-media('t') {
                width: 25%;
            }

            @include min-media('d') {
                width: 50%;
            }
        }

        [data-error=true] & {
            border: 1px solid $primary;
        }
    }

    &__error-text {
        color: $primary;
        font: 600 rem(12)/rem(14) 'Gilroy', sans-serif;
        margin-top: rem(10);
    }
}
