@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.card-tool {
    padding: 0 rem(32);
    background-color: $color-2;
    min-height: rem(522);
    position: relative;

    @include min-media('t') {
        min-height: rem(536);
    }

    &__title {
        padding: rem(90) 0 rem(50);
        display: flex;
        align-items: center;
        justify-content: center;
        color: $text;

        @include max-media('tMax') {
            font-size: rem(20);
            line-height: rem(27);
        }
    }

    &__wrapper {
        border-top: 1px solid $color-3;
        padding: rem(50) 0;
        display: flex;
        flex-direction: column;
    }

    &__item {
        display: flex;
        align-items: flex-start;
        font: 600 rem(16)/rem(19) 'Gilroy', sans-serif;
        color: $text;

        @include min-media('t') {
            font-size: rem(18);
            line-height: rem(19);
        }

        &-wrapper {
            & > * {
                display: block;
            }
        }

        &-price {
            margin-left: auto;
            flex-shrink: 0;
        }

        &-subtitle {
            margin-top: rem(6);
        }

        & + & {
            margin-top: rem(40);
        }
    }

    &__text {
        display: block;
        text-align: center;
    }

    &__link {
        width: auto;
        margin-top: rem(23);
        align-self: center;
    }
}
