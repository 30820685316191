@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.link {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $primary;
    height: rem(46);
    padding: 0 0 rem(6);
    background-color: rgba($color-4, 0);
    border-bottom: 2px solid rgba($color-4, 0);
    transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;

    @include max-media('mMax') {
        font-size: rem(16);
        line-height: rem(19);
        padding: 0 0 rem(14);
        height: rem(33);
    }

    & > * {
        position: relative;
        pointer-events: none;
    }

    &--base {
        &:before {
            content: '';
            position: absolute;
            right: 0;
            bottom: -2px;
            width: 100%;
            height: 2px;
            background-color: $primary;
            transform: scaleX(1);
            transform-origin: left;
            transition: transform 0.2s ease-out;
        }

        &:hover {
            &:before {
                transform: scaleX(0);
                transform-origin: right;
            }
        }
    }

    &--square {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: $primary;
        height: rem(46);
        padding: rem(12) rem(36);
        background-color: rgba($color-4, 0);
        border: 2px solid $primary;
        transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;
        z-index: 1;

        &:before {
            content: '';
            width: 100%;
            max-width: 0;
            height: 100%;
            background-color: $primary;
            position: absolute;
            top: 0;
            left: 0;
            transition: max-width 0.3s ease;
            z-index: -1;
        }

        & > * {
            position: relative;
            pointer-events: none;
        }

        &:hover {
            color: $color-4;

            &:before {
                max-width: 100%;
            }
        }
    }

    &--icon {
        display: inline-flex;
        height: auto;
        border: none;
        padding: 0;

        .link__text {
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s ease, visibility 0s;
            display: inline-block;
            color: $text;

            @include max-media('mMax') {
                opacity: 1;
                visibility: visible;
            }
        }

        .link__icon {
            margin-right: rem(8);
            width: rem(17);
            height: rem(17);
            display: block;
            fill: $color-4;
            stroke: $color-4;

            @include max-media('mMax') {
                fill: $primary;
                stroke: $primary;
            }
        }
    }
}
