@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.input {
    position: relative;
    width: 100%;
    padding-top: rem(20);

    &__label {
        position: relative;
        width: 100%;
        height: rem(44);
        padding-right: rem(20);
        border-top: 2px solid transparent;
        border-bottom: 2px solid black;
        z-index: 1;
        font-size: rem(16);
        font-weight: 600;
        display: block;
        pointer-events: none;

        &:before {
            content: '';
            width: 100%;
            max-width: 0;
            height: 2px;
            position: absolute;
            bottom: -2px;
            left: 0;
            background-color: $primary;
            transition: max-width 0.5s linear;
        }
    }

    &__placeholder {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0%, -50%);
        color: $color-6;
        font-size: rem(16);
        font-weight: 600;
        line-height: 1;
        transition: color 0.3s ease, font-size 0.3s ease, transform 0.3s ease;
        z-index: 2;
        pointer-events: none;
    }

    &__field {
        position: absolute;
        width: 100%;
        height: rem(44);
        top: rem(20);

        &:focus + .input__label:before,
        &:hover + .input__label:before {
            max-width: 100%;
        }

        &:focus,
        .input--up & {
            & + .input__label .input__placeholder {
                transform: translateY(-30px);
                color: $text;
            }
        }

        [data-error=true] & {
            color: $primary;

            & + .input__label {
                &:before {
                    max-width: 100%;
                }

                .input__placeholder {
                    color: $primary;
                }
            }
        }
    }

    &__icon {
        width: rem(14);
        height: rem(14);
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        [data-error=true] & {
            width: rem(12);
            height: rem(12);
        }
    }

    &__error-text {
        color: $primary;
        font: 600 rem(12)/rem(14) 'Gilroy', sans-serif;
        margin-top: rem(10);
    }
}
