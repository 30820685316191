@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.card-process {
    padding: rem(74) rem(32);
    border: 1px solid $color-3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    height: rem(422);
    position: relative;

    @include min-media('t') {
        height: rem(405);
    }

    @include min-media('xl') {
        padding: rem(74) rem(31);
    }

    &__title {
        margin-bottom: rem(20);
        display: inline-block;
        color: $text;
        transition: color 0.3s ease;

        &[href]:hover {
            color: $primary;
        }

        @include min-media('t') {
            margin-bottom: rem(20);
        }

        @include max-media('tMax') {
            font-size: rem(20);
            line-height: rem(27);
        }

        @include min-media('xl') {
            margin-bottom: rem(30);
        }
    }

    &__text {
        display: block;

        @include max-media('tMax') {
            font-size: rem(14);
            line-height: rem(23);
        }
    }

    &__title,
    &__text {
        width: 100%;
        position: relative;
        z-index: 1;

        @include min-media('xl') {
            width: rem(302);
        }
    }

    &__number {
        font: 800 rem(180)/1 'Gilroy', sans-serif;
        color: $color-2;
        position: absolute;
        left: rem(32);
        bottom: rem(5);
        transition: color 0.3s ease;
    }

    &:hover {
        .card-process__number {
            color: $primary;
        }
    }
}
