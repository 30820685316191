@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $primary;
    height: rem(46);
    padding: rem(12) rem(36);
    background-color: rgba($color-4, 0);
    border: 2px solid $primary;
    transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;
    z-index: 1;

    &:not(.button--underline):before {
        content: '';
        width: 100%;
        max-width: 0;
        height: 100%;
        background-color: $primary;
        position: absolute;
        top: 0;
        left: 0;
        transition: max-width 0.3s ease;
        z-index: -1;
    }

    & > * {
        position: relative;
        pointer-events: none;
    }

    &:not(.button--underline):hover {
        color: $color-4;

        &:before {
            max-width: 100%;
        }
    }

    &--underline {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: $primary;
        height: rem(40);
        padding: 0 0 rem(12);
        margin-bottom: rem(10);
        background-color: rgba($color-4, 0);
        border: none;
        border-bottom: 2px solid rgba($color-4, 0);
        transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;

        & > * {
            position: relative;
            pointer-events: none;
        }

        &:before {
            content: '';
            position: absolute;
            right: 0;
            bottom: -2px;
            width: 100%;
            height: 2px;
            background-color: $primary;
            transform: scaleX(1);
            transform-origin: left;
            transition: transform 0.2s ease-out;
        }

        &:hover {
            &:before {
                transform: scaleX(0);
                transform-origin: right;
            }
        }
    }
}
