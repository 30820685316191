@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    text-align: center;
    white-space: nowrap;
    z-index: 1000;
    
    &::after {
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 100%;
        content: '';
    }

    &__button {
        width: rem(17);
        height: rem(17);
        position: absolute;
        right: rem(30);
        top: rem(30);
        z-index: 2;

        &:before,
        &:after {
            content: '';
            width: rem(2);
            height: 100%;
            background: $color-6;
            position: absolute;
            top: 0;
            left: 50%;
            transition: background 0.3s ease;
        }

        &:before {
            transform: translateX(-50%) rotate(-45deg);
        }
        &:after {
            transform: translateX(-50%) rotate(45deg);
        }

        &:hover:before,
        &:hover:after{
            background: $primary;
        }
    }

    &__overlay {
        display: inline-block;
        vertical-align: middle;
        width: 100%;

        @include max-media('tMax') {
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100);
            background-color: $color-4;
        }

        @include min-media('md') {
            max-width: rem(942);
        }

        .small & {
            @include min-media('md') {
                max-width: rem(529);
            }
        }


    }

    &__wrapper {
        padding: rem(50) rem(20);
        width: 100%;
        text-align: left;
        white-space: normal;
        background-color: $color-4;
        color: $color-7;
        position: relative;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        height: 100%;

        @include max-media('tMax') {
            min-height: 100%;
        }

        @include min-media('t') {
            padding: rem(90) rem(60);
        }

        @include min-media('d') {
            padding: rem(90) rem(90) rem(109);
        }

        .small & {
            @include min-media('d') {
                padding: rem(90) rem(60);
            }
        }

        .popup--flex & {
            @include min-media('d') {
                display: flex;
                align-items: flex-start;
            }
        }
    }

    &__title {
        margin-bottom: rem(30);
    }

    &__link {
        margin-top: rem(30);
    }

    &__heading {
        margin-bottom: rem(30);

        .popup--flex & {
            @include min-media('d') {
                margin-top: rem(20);
                max-width: rem(286);
                margin-right: rem(66);
                margin-bottom: 0;
            }

            & + * {
                flex-grow: 1;
            }
        }

        &--center {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}
