@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.team {
    &__title {
        margin-bottom: rem(60);
        width: 100%;
        display: block;

        @include min-media('t') {
            max-width: rem(481);
            margin-bottom: rem(83);
        }

        @include min-media('xl') {
            max-width: rem(874);
            margin-bottom: rem(60);
        }
    }

    &__list {
        display: flex;
        align-items: center;
        margin: rem(-20);
        flex-wrap: wrap;

        & > * {
            margin: rem(20);
        }

        &-link {
            @include max-media('mMax') {
                width: 100%;
            }
        }
    }
}
