@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.technology {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    color: $text;
    transition: color 0.3s ease;
    width: rem(106);

    & > * {
        position: relative;
        pointer-events: none;
    }

    &[href]:hover {
        background-color: $primary;
        color: $color-4;
    }

    &__icon {
        width: rem(106);
        height: rem(106);
        display: block;
    }

    &__text {
        font: 600 rem(14)/rem(17) 'Gilroy', sans-serif;
        color: $text;
        margin-top: rem(10);
        display: inline-block;
        text-align: center;

        @include min-media('t') {
            font-size: rem(18);
            line-height: rem(19);
        }
    }
}
