@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;
@use "/src/styles/_fonts" as *;
@use "/src/styles/_text" as *;

.page {
    background-color: $color-4;
    min-height: 100vh;

    &__introduction {
        @include min-media('t') {
            margin-bottom: rem(60);
        }

        @include min-media('xl') {
            margin-bottom: 0;
        }
    }
}
