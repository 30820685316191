@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.tools {
    &__title {
        margin-bottom: rem(15);
        max-width: rem(695);
        width: 100%;
        display: block;

        @include min-media('t') {
            margin-bottom: rem(30);
        }
    }

    &__text {
        margin-bottom: rem(40);
        max-width: rem(695);
        width: 100%;
        display: block;

        @include min-media('t') {
            margin-bottom: rem(60);
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: rem(-9) 0;

        @include min-media('t') {
            margin: rem(-10) 0;
        }

        @include min-media('d') {
            margin: rem(-11);
        }
    }

    &__item {
        width: 100%;
        margin: rem(10) 0;

        @include min-media('t') {
            margin: rem(9) 0;
        }

        @include min-media('d') {
            width: calc(100% / 3 - #{rem(22)});
            margin: rem(11);
        }
    }
}
