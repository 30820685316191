@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.banner {
    @include max-media('mMax') {
        padding: 0;
        margin-top: rem(80);
    }

    @include max-media('tMax') {
        padding: 0;
        margin-top: rem(40);
    }

    &__wrapper {
        padding: rem(90) rem(100);
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: rem(250);
        position: relative;
        background-color: $color-2;
        flex-direction: column;

        @include max-media('sMax') {
            padding: rem(90) rem(70);
        }

        @include min-media('t') {
            padding: rem(102) rem(60);
            flex-direction: row;
        }

        @include min-media('xl') {
            padding: rem(102) rem(110);
        }
    }

    &__title {
        display: inline-block;
    }

    &__button {
        margin-top: rem(40);

        @include min-media('t') {
            margin-left: auto;
            margin-top: 0;
        }

        @include max-media('mMax') {
            width: 100%;
        }
    }
}
