@font-face {
	font-family: 'Gilroy';
	src: url('../assets/fonts/Gilroy/Gilroy-Regular.woff2') format('woff2'),
		url('../assets/fonts/Gilroy/Gilroy-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Gilroy';
	src: url('../assets/fonts/Gilroy/Gilroy-Semibold.woff2') format('woff2'),
		url('../assets/fonts/Gilroy/Gilroy-Semibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Gilroy';
	src: url('../assets/fonts/Gilroy/Gilroy-Bold.woff2') format('woff2'),
		url('../assets/fonts/Gilroy/Gilroy-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
