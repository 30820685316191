@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;
@use "/src/styles/_variables" as *;

/* text styles */
.heading-h1 {
    font: 700 rem(28)/rem(33) 'Gilroy', sans-serif;

    @include min-media('t') {
        font-size: rem(48);
        line-height: rem(58)
    }

    @include min-media('xl') {
        font-size: rem(54);
        line-height: rem(65)
    }
}

.heading-h2 {
    font: 700 rem(20)/rem(27) 'Gilroy', sans-serif;

    @include min-media('t') {
        font-size: rem(32);
        line-height: 132%;
    }

    @include min-media('xl') {
        font-size: rem(34);
        line-height: rem(45);
    }
}

.heading-2 {
    font: 700 rem(28)/rem(34) 'Gilroy', sans-serif;

    @include min-media('t') {
        font-size: rem(32);
        line-height: 132%;
    }

    @include min-media('xl') {
        font-size: rem(34);
        line-height: rem(45);
    }
}

.heading-h3 {
    font: 700 rem(20)/rem(27) 'Gilroy', sans-serif;

    @include min-media('t') {
        font-size: rem(24);
        line-height: rem(29)
    }
}

.text-primary {
    font: 400 rem(14)/rem(22) 'Gilroy', sans-serif;

    @include min-media('t') {
        font-size: rem(16);
        line-height: rem(24)
    }
}

.text-secondary {
    font: 600 rem(16)/rem(19) 'Gilroy', sans-serif;

    @include min-media('t') {
        font-size: rem(16);
        line-height: rem(24);
    }
}

.text-third {
    font: 600 rem(14)/rem(17) 'Gilroy', sans-serif;

    @include min-media('t') {
        font-size: rem(16);
        line-height: rem(24);
    }
}

mark {
    color: $primary;
    background-color: rgba(255, 255, 255, 0);
}

a:not([class]) {
    color: $primary;
    transition: color 0.3s ease;

    &:hover,
    &:active {
        color: $text;
    }
}

/* container styles */
.container {
    max-width: rem(1440);
    width: 100%;
    padding: rem(40) rem(30);
    margin: 0 auto;

    @include min-media('t') {
        padding: rem(60) rem(60);
    }

    @include min-media('xl') {
        padding: rem(70) rem(134) rem(70);
    }
}
