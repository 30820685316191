@use "/src/styles/_variables" as *;
@use "/src/styles/_function" as *;
@use "/src/styles/_media" as *;

.radio {
    &__label {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: $text;
        height: rem(45);
        padding: rem(14) rem(14) rem(12);
        background-color: $color-4;
        border: 1px solid $color-3;
        transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
        cursor: pointer;
        font-weight: 600;

        &:hover {
          border-color: $primary;
        }
      }

  input {
    width: 1px;
    height: 1px;
    position: absolute;

    &:focus  + .radio__label {
      border-color: $primary;
    }

    &[checked] + .radio__label {
        border-color: $primary;
        background-color: $primary;
        color: $color-4;
    }
  }
}
